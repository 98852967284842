<template>
  <div class="home">
<!--    轮播图-->
    <Banner />
<!--    产品中心-->
    <Product/>
<!--    产品优势-->
    <div class="advantage-box">
      <el-image fit="cover" :src="isMobile ? advantagePicMobile : advantagePic" class="advantage-pic"></el-image>
      <module-title title="产品优势" font-color="#FFFFFF" :margin-top="0" class="advantage-title"/>
    </div>

    <!--    技术团队-->
    <div class="group-boxes" :class="'group-boxes-' + isMobile">
      <module-title title="技术团队" :margin-top="isMobile?30:80"/>
      <div class="flex-between fixed-width-container" :class="isMobile?'padding-aside-15':''">
        <el-row :gutter="isMobile?20:40">
          <el-col :span="isMobile?12:6" v-for="(item, index) in groupList" :key="index">
            <div class="group-box" :class="'group-box-' + isMobile">
              <img :src="item.avatar" class="group-avatar" :class="'group-avatar-' + isMobile">
              <div class="group-name" :class="'group-name-' + isMobile">{{item.name}}</div>
              <div class="group-position" :class="'group-position-' + isMobile">{{item.position}}</div>
              <div>{{item.desc}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    产品案例-->
    <img :src="require('@images/index/pic_home_bg_3.svg')" class="aside-pic" v-if="!isMobile">
    <div class="case-box" :class="'case-box-' + isMobile">
      <module-title title="产品案例" :margin-top="0"/>
      <img class="fixed-width-container case-map" :src="require('@images/index/pic_home_map.svg')" :class="'case-map-' + isMobile">
    </div>
<!--    重大事件-->
    <div class="event-box" :class="'event-box-' + isMobile"  v-if="eventList.length">
      <div class="fixed-width-container">
        <module-title title="重大事件" :margin-top="0"/>
        <swiper class="swiper" :class="'swiper-' + isMobile" :options="swiperOption" :auto-update="true" v-if="eventList.length">
          <swiper-slide v-for="(item, index) in eventList" :key="index">
            <img class="banner-pic" :src="item.imageUrl"/>
            <div class="banner-type flex-between" :class="'banner-type-' + isMobile">
              <span class="text-ellipsis">{{item.title}}</span>
              <span style="width: 70px">{{item.createTime}}</span>
            </div>
          </swiper-slide>
          <!-- Add Pagination -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!--    产品战略合作伙伴-->
    <div class="bg-white partner-main-box" :class="'partner-main-box-' + isMobile" :style="eventList.length===0?'marginTop: 0':''">
      <div class="fixed-width-container">
        <module-title title="产品战略合作伙伴" :margin-top="0"/>
        <el-row :gutter="isMobile?15:40" class="partner-container" :class="'partner-container-' + isMobile">
          <el-col class="margin-bottom-15" :span="isMobile?item.spanMobile:item.span" v-for="(item, index) in strategicPartnerList" :key="index">
            <div class="flex-column-center">
              <div class="partner-box flex-center-item">
                <img class="partner-pic" :style="isMobile?item.styleMobile:item.style" :src="isMobile?item.picMobile:item.pic">
              </div>
              <span class="margin-top-15" style="text-align: center" v-html="item.name" v-if="!isMobile"></span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    产品生态合作伙伴-->
    <div class="partner-main-box" :class="'partner-main-box-' + isMobile" :style="eventList.length===0?'marginTop: 0':''">
      <div class="fixed-width-container">
        <module-title title="产品生态合作伙伴" :margin-top="0"/>
        <el-row :gutter="isMobile?15:40" class="partner-container" :class="'partner-container-' + isMobile">
          <el-col class="margin-bottom-15" :span="isMobile?item.spanMobile:item.span" v-for="(item, index) in partnerList" :key="index">
            <div class="flex-column-center">
              <div class="partner-box flex-center-item">
                <img class="partner-pic" :style="isMobile?item.styleMobile:item.style" :src="isMobile?item.picMobile:item.pic">
              </div>
              <span class="margin-top-15" style="text-align: center" v-html="item.name" v-if="!isMobile"></span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    行业协会组织-->
    <div class="bg-white partner-main-box" :class="'partner-main-box-' + isMobile" :style="eventList.length===0?'marginTop: 0':''">
      <div class="fixed-width-container">
        <module-title title="行业协会组织" :margin-top="0"/>
        <el-row :gutter="isMobile?15:40" class="partner-container" :class="'partner-container-' + isMobile">
          <el-col class="margin-bottom-15" :span="isMobile?item.spanMobile:item.span" v-for="(item, index) in associationList" :key="index">
            <div class="flex-column-center">
              <div class="partner-box flex-center-item">
                <img class="partner-pic" :style="isMobile?item.styleMobile:item.style" :src="isMobile?item.picMobile:item.pic">
              </div>
              <span class="margin-top-15" style="text-align: center" v-html="item.name" v-if="!isMobile"></span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!--    行业合作伙伴-->
    <div class="fixed-width-container">
      <module-title title="行业合作伙伴" :margin-top="isMobile?25:80"/>
      <el-row :gutter="isMobile?15:40" class="industry-partner-container" :class="'industry-partner-container-' + isMobile">
        <el-col class="margin-bottom-15" :span="isMobile?item.spanMobile:item.span" v-for="(item, index) in industryPartnerList" :key="index">
          <div class="flex-column-center">
            <div class="partner-box flex-center-item">
              <img class="industry-partner-pic" :style="isMobile?item.styleMobile:item.style" :src="item.pic">
            </div>
            <span class="margin-top-15" style="text-align: center" v-html="item.name" v-if="!isMobile"></span>
          </div>
        </el-col>
      </el-row>
    </div>
<!--    招募-->
    <Recruit />
  </div>
</template>

<script>
import { Banner, Product, Recruit } from '@components'
import { mapGetters } from 'vuex'
export default {
  name: 'Home',

  components: {
    Banner,
    Product,
    Recruit
  },
  data () {
    return {
      advantagePic: require('@images/index/pic_home_cpys.svg'),
      advantagePicMobile: require('@images/index/pic_home_cpys_mobile.svg'),
      strategicPartnerList: [
        {
          pic: require('@images/index/pic_hzhb_zglt_logo.jpg'),
          picMobile: require('@images/index/mobile/pic_hzhb_zglt_logo.jpg'),
          name: '中国联合网络通信有限公司浙江省分公司',
          spanMobile: 8,
          span: 8,
          styleMobile: {
            width: '89px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zgyd_logo.jpg'),
          picMobile: require('@images/index/mobile/pic_hzhb_zgyd_logo.jpg'),
          name: '浙江移动信息系统集成有限公司',
          spanMobile: 8,
          span: 8,
          styleMobile: {
            width: '89px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zgdx_logo.jpg'),
          picMobile: require('@images/index/mobile/pic_hzhb_zgdx_logo.jpg'),
          name: '中国电信股份有限公司浙江分公司',
          spanMobile: 8,
          span: 8,
          styleMobile: {
            width: '89px',
            height: '50px',
            padding: '10px 0'
          }
        }
      ],
      partnerList: [
        {
          pic: require('@images/index/pic_hzhb_rmyy_logo.png'),
          picMobile: require('@images/index/pic_hzhb_rmyy_logo.png'),
          name: '浙江省人民医院<br>&nbsp;',
          spanMobile: 12,
          span: 12,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_jjzhzx_logo.png'),
          picMobile: require('@images/index/pic_hzhb_jjzhzx_logo.png'),
          name: '浙江省急救指挥中心<br>&nbsp;',
          spanMobile: 12,
          span: 12,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zjdx_logo.png'),
          picMobile: require('@images/index/mobile/pic_hzhb_zjdx_logo.png'),
          name: '浙江大学',
          spanMobile: 7,
          span: 8,
          styleMobile: {
            width: '64px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zjlgdx_logo.png'),
          picMobile: require('@images/index/mobile/pic_hzhb_zjlgdx_logo.png'),
          name: '浙江理工大学',
          spanMobile: 10,
          span: 8,
          styleMobile: {
            width: '118px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zjkjxy_logo.png'),
          picMobile: require('@images/index/mobile/pic_hzhb_zjkjxy_logo.png'),
          name: '浙江科技学院',
          spanMobile: 7,
          span: 8,
          styleMobile: {
            width: '64px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_hskj_logo.png'),
          picMobile: require('@images/index/pic_hzhb_hskj_logo.png'),
          name: '浙江华是科技股份有限公司',
          spanMobile: 12,
          span: 8,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_mr_logo.png'),
          picMobile: require('@images/index/pic_hzhb_mr_logo.png'),
          name: '深圳迈瑞生物医疗电子股份有限公司',
          spanMobile: 12,
          span: 8,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        },
        {
          pic: require('@images/index/pic_hzhb_zsbykj_logo.png'),
          picMobile: require('@images/index/mobile/pic_hzhb_zsbykj_logo.png'),
          name: '泽世百易医疗科技（北京）有限公司',
          spanMobile: 24,
          span: 8,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        }
      ],
      associationList: [
        {
          pic: require('@images/index/pic_hzhb_zgylqx_logo.png'),
          picMobile: require('@images/index/mobile/pic_hzhb_zgylqx_logo.png'),
          name: '中国医疗器械行业协会会员单位',
          spanMobile: 24,
          span: 24,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        }
      ],
      groupList: [
        {
          avatar: require('@images/index/pic_jstd_photo_1.png'),
          name: '何婧',
          position: '首席执行官',
          desc: '公司创始人'
        },
        {
          avatar: require('@images/index/pic_jstd_photo_5.png'),
          name: '周群一',
          position: '首席技术官',
          desc: '博士，浙江省新世纪151人才工程'
        },
        {
          avatar: require('@images/index/pic_jstd_photo_3.png'),
          name: '吕旭东',
          position: '医学信息学专家顾问',
          desc: '博导，浙江大学教授，医疗健康信息工程技术研究所副所长'
        },
        {
          avatar: require('@images/index/pic_jstd_photo_4.png'),
          name: '李昊旻',
          position: '大数据分析专家顾问',
          desc: '硕导，高级工程师，浙大医学院附属儿童医院临床数据中心副主任'
        }
      ],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        loop: true
      },
      eventList: [],
      industryPartnerList: [
        {
          pic: require('@images/index/pic_logo_ym.png'),
          name: '远盟康健科技有限公司',
          spanMobile: 24,
          span: 24,
          styleMobile: {
            width: '145px',
            height: '50px',
            padding: '10px 0'
          }
        }
        // ,
        // {
        //   pic: require('@images/index/pic_logo_jr.png'),
        //   name: '杭州聚仁医疗科技有限公司',
        //   spanMobile: 12,
        //   span: 8,
        //   styleMobile: {
        //     width: '145px',
        //     height: '50px',
        //     padding: '10px 0'
        //   }
        // },
        // {
        //   pic: require('@images/index/pic_logo_yy.png'),
        //   name: '杭州佑医科技有限公司',
        //   spanMobile: 24,
        //   span: 8,
        //   styleMobile: {
        //     width: '145px',
        //     height: '50px',
        //     padding: '10px 0'
        //   }
        // }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  mounted () {
    this.getEventList()
  },
  methods: {
    apply () {
      this.$message.warning('功能暂未开放')
    },
    getEventList () {
      window.$common.post('/api/news/get-home-news', {}).then(res => {
        if (res.retCode === this.$globalData.RET_CODE.SUCCESS) {
          res.obj.map(item => {
            item.createTime = new Date(item.createTime.replace(/-/g, '/')).format('yyyy/MM/dd')
          })
          this.eventList = res.obj
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  // 大屏
  @media screen and (min-width: 750px) {
    .advantage-box {
      position: relative;
      margin-top: -100px;
    }
    .advantage-pic{
      width: 100%;
    }
    .advantage-title {
      position: absolute;
      top: 50px;
      width: 100%;
    }
  }
  // 小屏
  @media screen and (max-width: 750px) {
    .advantage-box {
      position: relative;
      margin-top: -35px;
    }
    .advantage-pic{
      width: 100%;
    }
    .advantage-title {
      position: absolute;
      top: 30px;
      width: 100%;
    }
  }

  .partner-container{
    margin-top: 60px;
    font-size: 18px;
    color: #061549;
    &-true{
      margin-top: 20px;
      padding: 0 15px;
      margin-left: 0!important;
      margin-right: 0!important;
    }
  }

  .industry-partner-container{
    margin-top: 60px;
    padding-bottom: 60px;
    font-size: 18px;
    color: #061549;
    &-true{
      margin-top: 20px;
      padding: 0 15px 25px;
      margin-left: 0!important;
      margin-right: 0!important;
    }
  }

  .partner-box{
    width: 100%;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(63,99,232,0.15);
    &:last-child{
      margin-right: 0;
    }
  }

  .partner-pic{
    width: 245px;
    height: 80px;
    display: inline-block;
    object-fit: contain;
    padding: 20px;
  }

  .industry-partner-pic{
    width: 287px;
    height: 80px;
    display: inline-block;
    object-fit: contain;
    padding: 20px 40px;
  }

  .case-map{
    display: block;
    margin-top: 60px;
    &-true{
      margin-top: 15px;
      width: calc(100% - 30px);
    }
  }

  .case-box{
    background: @white;
    padding-bottom: 70px;
    &-true{
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .event-box{
    background: url("../../images/index/pic_home_bg_4.svg") no-repeat;
    background-position: top center;
    background-size: 100%;
    padding-top: 110px;
    &-true{
      background: none;
      padding-top: 25px;
    }
  }

  .group-boxes{
    padding-bottom: 80px;
    &-true{
      padding-bottom: 25px;
    }
  }

  .group-avatar{
    width: 110px;
    border-radius: 100px;
    box-shadow: 0 4px 6px 0 rgba(56,80,167,0.15);
    margin-top: -55px;
    &-true{
      width: 85px;
      margin-top: -42.5px;
    }
  }

  .group-name{
    font-size: 18px;
    color: #061549;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 8px;
    line-height: 1;
    &-true{
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 2px;
    }
  }

  .group-box{
    margin-top: 120px;
    position: relative;
    height: 250px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(63,99,232,0.15);
    border-radius: 8px;
    font-size: 14px;
    color: #747EA3;
    text-align: center;
    line-height: 30px;
    padding: 0 25px;
    &-true{
      margin-top: 60px;
      height: 175px;
      font-size: 13px;
      line-height: 20px;
      padding: 0 15px;
    }
  }

  .group-position{
    font-size: 14px;
    color: #F58F14;
    line-height: 1;
    padding: 8px 0 4px;
    &-true{
      font-size: 13px;
    }
  }
  .swiper{
    margin-top: 60px;
    height: 570px;
    &-true{
      margin-top: 15px;
      height: 167px;
      width: calc(100% - 20px);
    }
  }
  .banner-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .banner-type{
    background: rgba(0,0,0,0.70);
    height: 55px;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    &-true{
      height: 26px;
      font-size: 12px;
      padding: 0 5px;
    }
  }
  /deep/.swiper-pagination-bullets{
    bottom: 75px;
  }
  .swiper-true{
    .swiper-pagination-bullets{
      bottom: 30px;
    }
    /deep/.swiper-pagination-bullet {
      width:8px;
      height:8px;
      background: rgba(255,255,255,0.50);
    }
    /deep/.swiper-pagination-bullet-active {
      width:8px;
      height:8px;
      background: #FFFFFF;
    }
  }
  /deep/.swiper-pagination-bullet {
    width:12px;
    height:12px;
    background: rgba(255,255,255,0.50);
  }
  /deep/.swiper-pagination-bullet-active {
    width:12px;
    height:12px;
    background: #FFFFFF;
  }
  .partner-main-box{
    margin-top: 60px;
    padding: 60px 0;
    &-true{
      margin-top: 25px;
      padding: 25px 0;
    }
  }
</style>
